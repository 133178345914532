.m-header-nav {
  @include list-unstyled;
  @include utility-primary;

  @include respond(break(60)) {
    font-size: 16px;
  }

  &__item {
    @include link-primary;

    &:not(:last-child) {
      padding-bottom: padding(x-small);

      @include respond(break(60)) {
        padding-bottom: 32px;
      }
    }
  }
}
