// Variables
// ----------

// Colors
// --------------------------------------------------------
$palette: (
  white: #fff,
  neutral-10: #d8d8d8,
  neutral-20: #a7a7a7,
  neutral-30: #919191,
  neutral-40: #777,
  neutral-50: #4a4a4a,
  neutral-60: #363636,
  neutral-70: #333,
  neutral-80: #222,
  black: #000,
  primary: #4f56ab,
  secondary: #4de6f8,
  secondary-dark: #009da8,
  secondary-light: #d4faff,
  secondary-pale: #e8fdff,
);

// Responsive breakpoints
// --------------------------------------------------------
$breakpoints: (
  130: 1280px,
  110: 1120px,
  60: 620px,
  50: 540px,
  30: 380px,
);

// Measurements
// --------------------------------------------------------
$container-widths: (
  max: 1280px,
  wide: 1120px,
  base: 840px,
);
$padding-sizes: (
  large: 80px,
  medium: 50px,
  small: 30px,
  x-small: 20px,
  x-x-small: 15px,
);
$border-radius: 6px;
$duration: 0.2s;
$timing: ease;

// Typography
// --------------------------------------------------------
$line-height-base: 1.3;
$font-stack-base: 'Moderat', arial, sans-serif;
$font-stack-heading: 'Begum', 'Times New Roman', serif;
$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;

// Appearance
// --------------------------------------------------------

// Cross-component variables
// --------------------------------------------------------
