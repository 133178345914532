@use 'sass:math';

.m-post-list {
  $column-gap: 34px;
  $grid-item-min-width: math.div(container-width(base) - $column-gap * 2, 3);

  @include list-unstyled;

  // &--rows {}

  &--grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$column-gap;

    @supports (grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))) {
      display: grid;
      grid-template-columns:
        repeat(
          auto-fill,
          minmax($grid-item-min-width, 1fr)
        );
      column-gap: $column-gap;
      margin-left: 0;
    }
  }

  &__item {
    .m-post-list--grid & {
      flex-basis: $grid-item-min-width;
      margin-left: $column-gap;

      @supports (grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))) {
        margin-left: 0;
      }
    }

    &:first-child {
      .m-post-list--rows & {
        @include fluid-scale(margin-top, 58px, 50px);
      }
    }

    // stylelint-disable-next-line no-descending-specificity
    & + & {
      .m-post-list--rows & {
        @include fluid-scale(margin-top, 72px, 50px);
      }
    }

    &--bordered {
      .m-post-list--rows & {
        @include fluid-scale(padding-top, 40px, padding(x-small));
        @include fluid-scale(padding-bottom, 55px, padding(small));

        border-top: 1px solid palette(primary);

        &:first-child,
        + .m-post-list__item--bordered {
          margin-top: 0;
        }
      }
    }
  }
}
