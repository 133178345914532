.m-hero {
  &__figure {
    position: relative;
  }

  &__button {
    @include utility-primary;

    position: absolute;
    right: 20px;
    display: flex;
    align-items: center;
    padding: 8px 9px 8px 12px;
    color: palette(white);
    background-color: palette(primary);
    border-radius: $border-radius;
    transition: background-color $duration $timing;

    &--open {
      bottom: 20px;
    }

    &--close {
      top: 20px;
    }

    &:hover {
      background-color: palette(secondary-dark);
    }
  }

  &__button-label {
    display: inline-block;
  }

  &__icon-container {
    position: relative;
    width: 25px;
  }

  &__icon {
    display: block;
    padding-left: 6px;

    > svg {
      width: 20px;
    }
  }

  &__info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    padding: 77px 20px 25px 40px;
    overflow: auto;
    text-align: right;
    background-color: palette(secondary-pale);
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    opacity: 0;
    transition: opacity $duration $timing;

    &.open {
      opacity: 1;
    }
  }

  &__caption {
    font-size: 15px;
    line-height: 1.2;
  }

  &__credit {
    @include utility-primary;

    padding-top: 12px;
    font-size: 14px;
  }

  &__credit-content {
    color: palette(neutral-70);
  }

  &__image {
    width: 100%;
    height: auto;
    border-radius: $border-radius;
  }
}
