.m-menu-secondary {
  &:not(:only-child) {
    margin-top: 24px;
  }

  &__list {
    @include list-unstyled;

    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin-top: 30px;
    margin-right: 37px;
  }

  &__link {
    @include link-primary;
    @include utility-primary;

    display: block;
    padding-top: 11px;
    border-top: 1px solid;
  }
}
