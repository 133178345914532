@use 'sass:color';

.m-header {
  $admin-bar-pos-breakpoint: 600px;

  top: var(--admin-bar-height, 0);
  background-color: palette(secondary-pale);

  @supports (position: sticky) {
    position: sticky;
    box-shadow: 0 2px 8px color.adjust(palette(neutral-50), $alpha: -0.9);
  }

  @include respond($admin-bar-pos-breakpoint) {
    top: 0;
  }

  &__inner {
    @include proto-container(
      container-width(base),
      padding(large),
      padding(x-small)
    );

    display: flex;
    align-items: center;
    justify-content: space-between;

    @include fluid-scale(padding-top padding-bottom, 24px, 17px);
  }

  &__site-name {
    @include heading-quaternary;

    display: block;
  }

  &__name-text {
    @include respond(break(60)) {
      display: none;
    }
  }

  &__logo {
    display: inline-block;
    width: 44px;
    height: 34.69px;
    margin: -3px 10px -4px 0;

    @include respond(break(30)) {
      width: 38px;
      margin-right: 5px;
    }
  }
}
