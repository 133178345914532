.m-header-home {
  &__inner {
    @include container-primary;
    @include fluid-scale(padding-top padding-bottom, 70px, padding(medium));

    display: flex;
    align-items: center;
    justify-content: space-between;

    @include respond(break(60)) {
      flex-direction: column;
    }
  }

  &__site-name {
    @include heading-tertiary;
    @include link-primary;

    display: block;

    @include respond(break(60)) {
      padding-bottom: 33px;
      text-align: center;
    }
  }

  &__logo {
    display: inline-block;
    width: 60px;
    margin-right: 12px;

    @include respond(break(60)) {
      display: block;
      width: 80px;
      margin: 0 auto 12px;
    }
  }
}
