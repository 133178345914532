// @font-face-specific mixins
// -----
@mixin embedFont($font-name, $font-filepath-and-name, $font-weight, $font-style) {
  @font-face {
    font-family: $font-name;
    font-style: $font-style;
    font-weight: $font-weight;
    src:
      url('../fonts/#{$font-filepath-and-name}.woff2') format('woff2'),
      url('../fonts/#{$font-filepath-and-name}.woff') format('woff');
  }
}

// @font-face declarations
// --------------------------
// Do your @font-face embedding here, in the privacy of your own partial
// `embedFont` must be used once per combination of weight and style

@include embedFont('Begum', 'Begum-Italic', $regular, italic);
@include embedFont('Begum', 'Begum-Regular', $regular, normal);
@include embedFont('Moderat', 'Moderat-Light-Italic', $light, italic);
@include embedFont('Moderat', 'Moderat-Light', $light, normal);
@include embedFont('Moderat', 'Moderat-Medium-Italic', $medium, italic);
@include embedFont('Moderat', 'Moderat-Medium', $medium, normal);
