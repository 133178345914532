// Appearance
// --------------------

// Appearance mixins contain primarily texture parameters: background-color, transparency, borders, etc.

// These should be limited to appearance-related properties only.

// Visibility
// --------------------------------------------------------
.a-pos-rel {
  position: relative;
}

.a-pos-abs {
  position: absolute;
}

.a-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

.a-hide-overflow {
  overflow: hidden;
}

@mixin focus-default {
  .js-focus-visible &:focus:not(.focus-visible) {
    outline: 0;
  }

  &.focus-visible {
    outline: solid 3px;
  }
}

// Color
// --------------------------------------------------------
// Create a `.a-color-` class for each color in $palette map
@each $name, $value in $palette {
  .a-color-#{$name + ""} {
    color: $value;
  }
}

// Create a `.a-bg-` class for each color in $palette map
@each $name, $value in $palette {
  .a-bg-#{$name + ""} {
    background-color: $value;
  }
}

// Icons
// --------------------------------------------------------
@mixin proto-icon {
  display: inline;
  vertical-align: middle;
  fill: currentcolor;
}

.a-icon {
  @include proto-icon;
}

// Screen Reader
// --------------------------------------------------------
@mixin screenReaderText {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

.a-screen-reader-text {
  @include screenReaderText;
}

// Links, Buttons, inputs
// --------------------------------------------------------
@mixin button-unstyled {
  appearance: none;
  padding: 0;
  background: transparent;
  border: 0;
}

@mixin button-primary {
  @include utility-primary;

  padding: 11px padding(x-small);
  text-align: center;
  border: 1px solid palette(primary);
  border-radius: $border-radius;
  transition: all $duration $timing;
  transition-property: color, background-color, border-color;

  &:hover {
    color: palette(white);
    background-color: palette(secondary-dark);
    border-color: palette(secondary-dark);
  }
}

.a-button-primary {
  @include button-primary;
}

@mixin input-primary {
  @include heading-tertiary;

  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid palette(primary);

  &:focus-visible {
    outline: 0;
  }
}

@mixin input-secondary {
  @include copy-secondary;

  padding: 10px 0 4px;
  color: palette(neutral-80);
  background: transparent;
  border: 0;
  border-bottom: 1px solid palette(primary);
  outline: 0;
}

// Images
// --------------------------------------------------------
@mixin lighten-image {
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    background-color: palette(secondary-dark);
    border-radius: 6px;
    mix-blend-mode: lighten;

    // trigger opacity change on interaction
    opacity: 0;
    transition: opacity $duration;
  }
}

// Transitions
// --------------------------------------------------------
