.m-faq-list {
  @include copy-primary;

  &__question {
    @include focus-default;

    display: flex;
    justify-content: space-between;
    list-style: none;
    cursor: pointer;
    transition: color $duration $timing;

    &::before {
      display: none;
    }

    &::-webkit-details-marker {
      display: none;
    }

    &:hover {
      color: palette(primary);
    }
  }

  &__icon-container {
    position: relative;
    width: 40px;
  }

  &__icon {
    position: absolute;
    right: 0;
    transition: opacity $duration $timing;
  }

  &__item[open] {
    .m-faq-list__question {
      padding-bottom: 22px;
    }

    .m-faq-list__icon--plus {
      opacity: 0;
    }
  }

  &__item {
    padding: 25px 0;
    border-top: 1px solid palette(primary);

    &:last-child {
      border-bottom: 1px solid palette(primary);
    }
  }
}
