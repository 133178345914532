.m-logo {
  display: inline;
  width: 100%;
  height: auto;
  vertical-align: middle;

  &__stem {
    fill: currentcolor;
    transition: fill $duration $timing;
  }

  &__leaves {
    fill: palette(secondary);
  }
}
