.m-filter-menu {
  @include proto-utility;

  position: relative;
  display: inline-block;
  font-size: 14px;
  color: palette(neutral-30);
  letter-spacing: 2.32px;
  transition: color $duration $timing;

  &:hover {
    color: palette(secondary-dark);
  }

  &__select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
}
