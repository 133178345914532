.m-content-rte {
  .wp-block-image {
    > [class^='align'] {
      padding-top: 25px;
    }
  }

  p,
  ul,
  ol,
  figcaption {
    @include copy-primary;
  }

  p,
  figcaption {
    a:not(.m-ext-link) {
      @include link-primary;

      text-decoration: underline;
    }
  }

  figcaption {
    font-size: 12px;
  }

  h5,
  h6 {
    @include heading-quaternary;
  }

  // h1,
  // h2,
  // h3,
  // h4,
  // h5,
  // h6 {
  //   // padding-bottom: 12px;
  // }

  > p,
  > ul,
  > ol,
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6,
  > figure,
  > [class^='wp-'] {
    @include container-primary;
  }

  p,
  ul,
  ol {
    + p,
    + ul,
    + ol,
    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      padding-top: 25px;
    }

    + .m-page-section.a-bg-secondary-pale {
      @include fluid-scale(margin-top, 75px, 50px);
    }
  }

  img {
    max-width: 100%;
    border-radius: $border-radius;
  }

  blockquote {
    @include fluid-scale(padding-top padding-bottom, 45px, padding(small));

    margin: 0 3.5em;
    text-align: center;

    p {
      @include heading-tertiary;
    }
  }
}
