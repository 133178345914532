.m-page-section {
  @include fluid-scale(padding-top, 76px, 56px);
  @include fluid-scale(padding-bottom, 56px, 76px);

  &--callout + &--callout,
  &--callout-blue + &--callout-blue {
    padding-top: 0;
  }
  
  &--no-padding-top {
    padding-top: 0;
  }

  &--small-padding-top {
    @include fluid-scale(padding-top, 44px, 35px);
  }

  &--anchor {
    scroll-margin-top: calc(var(--site-header-height, 74px) + 1em);
  }

  &__inner {
    @include container-primary;
  }

  &.a-bg-white + &.a-bg-white {
    margin-top: 0;
  }

  &.a-bg-secondary-pale + &.a-bg-secondary-pale {
    margin-top: 0;
  }

  &__header {
    &--flex {
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      @include respond(break(60)) {
        flex-direction: column;
      }
    }
  }

  &__header-link {
    @include respond(break(60)) {
      margin-top: 16px;
    }
  }

  &__lead {
    @include fluid-scale(font-size, 20px, 18px);
    @include fluid-scale(line-height, 30px, 25px);

    max-width: 735px;
    margin-top: 20px;
  }

  &__body {
    .m-page-section__header + & {
      @include fluid-scale(margin-top, 60px, 50px);
    }

    .m-page-section__inner + & {
      margin-top: 15px;
    }
  }

  &__subtitle {
    .m-page-section__title + & {
      @include fluid-scale(margin-top, padding(x-x-small), 10px);
    }
  }

  &__title {
    &--primary {
      @include heading-primary;

      margin-bottom: -10px;
      text-transform: none;
    }

    &--secondary {
      @include fluid-scale(margin-bottom, -40px, -25px);
    }
  }
}
