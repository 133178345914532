/*!

	Styles for October Seed
	By Cast Iron Coding (office@castironcoding.com)

*/

// Abstracts
@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins',
  'abstracts/fonts';

// Vendors
@import 'lib/normalize';

// Utility
// ----------
// Utility mixins & classes for common typography, appearance, layout, and state styles.
// These are designed to be scoped to distinct concerns in styling,
// such as the `font-size` and `font-family` of a common heading presentation,
// or the `width` and `margin` of a recurring container style.
// Utility classes are indicated by a prefix:
// * `t-` - typography
// * `a-` - appearance
// * `l-` - layout
// * `is-`/`has-` - state
@import 'utility/typography', 'utility/appearance', 'utility/layout',
  'utility/state';

// Base styles
@import 'base/base', 'base/typography';

// Modules
// ----------
// Self-contained, reuseable blocks of markup.
// Indicated by the class prefix `.m-`.
@import 'modules/index';

// Z-Stack
@import 'z-stack';
