.m-story-detail {
  &__image {
    @include fluid-scale(margin-bottom, 38px, 23px);

    max-width: 100%;
  }

  &__page-heading {
    @include link-primary;
    @include fluid-scale(padding-bottom, 33px, padding(x-small));
    @include fluid-scale(margin-bottom, 38px, 23px);

    border-bottom: 1px solid palette(primary);
  }

  &__title {
    @include heading-tertiary;

    padding-bottom: 7px;
    text-transform: none;
  }

  &__date {
    @include fluid-scale(padding-bottom, 28px, padding(x-small));

    font-size: 14px;
    font-weight: $medium;
    text-transform: uppercase;
    letter-spacing: 2.5px;
  }
}
