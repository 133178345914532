.m-pagination {
  @include fluid-scale(margin-bottom, padding(medium), padding(small));

  &__list {
    @include list-unstyled;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    margin-top: 1em;

    & + & {
      margin-left: 1.5em;
    }
  }

  &__link {
    @include utility-primary;

    padding: 0.25em;

    &:not(.is-current, .is-disabled) {
      @include link-primary;
    }

    &.is-disabled {
      color: palette(neutral-30);
    }

    &.is-current {
      border-bottom: 1px solid;
    }
  }
}
