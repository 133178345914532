.m-section-header {
  @include fluid-scale(padding-bottom, 35px, 25px);

  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid palette(primary);

  @include respond(break(60)) {
    flex-direction: column;
  }

  &--unbordered {
    border-bottom: none;
  }

  &__title {
    @include heading-primary;

    text-transform: none;
  }
}
