// Form Primary Components
// -----------------------
// Currently used only as sign-up form, but can be
// abstracted for other use

.form-primary {
  input[type='text'],
  input[type='email'] {
    @include input-secondary;

    display: block;
    width: 100%;

    + input[type='text'],
    + input[type='email'] {
      padding-top: 16px;
    }
  }

  input[type='submit'] {
    @include button-primary;

    margin-top: 34px;
  }
}
