@use 'sass:color';

.m-nav-utility {
  position: relative;
  display: inline-block;

  &:not(:first-child) {
    padding-left: 22px;

    @include respond(break(60)) {
      padding-left: padding(x-x-small);
    }

    @include respond(break(30)) {
      padding-left: 7px;
    }
  }

  &__mobile-close {
    display: none;

    @include respond(break(60)) {
      position: absolute;
      top: 22px;
      right: 20px;
      display: block;
    }
  }

  &__button,
  &__mobile-close {
    @include utility-primary;

    transition: color $duration $timing;

    &:hover {
      color: palette(secondary-dark);
    }

    svg {
      position: relative;
      top: -1px;
    }
  }

  &__popover {
    position: absolute;
    top: 40px;
    padding: 39px;
    background: palette(secondary-light);
    border-radius: $border-radius;
    box-shadow: 0 17px 40px -16px color.adjust(palette(neutral-80), $alpha: -0.59);
    opacity: 0;
    transform: translateX(-50%);

    &--narrow {
      left: 50%;
    }

    &--wide {
      left: -58px;
    }

    &.is-open {
      opacity: 1;
      transition: opacity $timing $duration;
    }

    &.is-closed {
      visibility: hidden;
      opacity: 0;
      transition: opacity $timing $duration;
    }

    @include respond(break(60)) {
      position: fixed;
      top: var(--admin-bar-height, 0);
      left: 100%;
      width: 100%;
      min-height: 100%;
      border-radius: 0;
      opacity: 1;
      transform: translateX(0);

      &.is-open {
        transition: transform $timing $duration;
        transform: translateX(-100%);
      }
    }
  }

  &__popover-inner {
    position: relative;

    @include respond(break(60)) {
      padding-top: 60px;
    }

    &--narrow::before {
      right: 0;
      left: 0;
    }

    &--wide::before {
      right: 15px;
    }

    &::before {
      position: absolute;
      top: -48px;
      display: block;
      width: 20px;
      height: 20px;
      margin: auto;
      content: '';
      background-color: palette(secondary-light);
      transform: rotate(45deg);

      @include respond(break(60)) {
        display: none;
      }
    }
  }

  &__input {
    @include input-primary;

    &::placeholder {
      color: palette(primary);
    }

    @include respond(break(60)) {
      width: 100%;
    }
  }

  &__label {
    @include utility-primary;

    display: block;
    padding-top: 25px;

    @include respond(break(60)) {
      padding-top: 48px;
    }
  }
}
