.m-ext-link {
  @include utility-primary;

  display: inline-flex;
  align-items: center;
  padding: 0.5em 1.286em;
  text-transform: uppercase;
  border: 1px solid;
  border-radius: 6px;
  transition: color $duration $timing;

  &__text {
    margin-left: 1em;
  }

  &:hover,
  &:focus-visible {
    color: palette(secondary-dark);
    outline: 0;
  }
}
