@use 'sass:math';

.m-category-nav {
  $grid-gap: 18px;
  $grid-item-min-width: math.div(container-width(base) - $grid-gap * 2, 3);

  &__list {
    @include list-unstyled;

    display: flex;
    flex-wrap: wrap;
    margin-top: -$grid-gap;
    margin-left: -$grid-gap;

    @supports (grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))) {
      display: grid;
      grid-template-columns:
        repeat(
          auto-fill,
          minmax($grid-item-min-width, 1fr)
        );
      grid-gap: $grid-gap;
      margin-top: 0;
      margin-left: 0;
    }
  }

  &__item {
    flex-basis: $grid-item-min-width;
    margin-top: $grid-gap;
    margin-left: $grid-gap;

    @supports (grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))) {
      margin-top: 0;
      margin-left: 0;
    }
  }

  &__link {
    @include utility-primary;
    @include link-primary;

    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 18px;
    color: palette(primary);
    background-color: palette(secondary-pale);

    &--center {
      align-items: center;
    }
  }

  &__icon {
    margin-left: 20px;
  }
}
