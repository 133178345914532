.m-post-teaser {
  display: flex;
  align-items: flex-start;

  &--row {
    + .m-post-teaser--row {
      @include fluid-scale(padding-top, 25px, padding(x-small));
    }

    @include respond(break(60)) {
      flex-direction: column;
    }
  }

  &--column {
    flex-direction: column;
    height: 100%;
    padding-bottom: 13px;
    border-bottom: 1px solid palette(primary);
  }

  &--border-top {
    padding-top: 24px;

    // border-top: 1px solid palette(primary);
  }

  &__header {
    order: 1;
    max-width: 100%;

    .m-post-teaser--column & {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__link {
    @include link-primary;

    display: inline-block;
  }

  &__title {
    @include heading-tertiary;

    color: inherit;
    text-transform: none;

    &--small {
      @include heading-quaternary;

      color: inherit;
    }

    &--unlinked {
      color: palette(primary);
    }
  }

  h2 {
    padding-bottom: 0;
  }

  &__meta {
    margin-top: 7px;
    font-size: 14px;
    font-weight: $medium;
    text-transform: uppercase;
    letter-spacing: 0.179em;
  }

  &__meta-item {
    margin-top: 0.5em;
    font-size: inherit;
    color: palette(primary);
  }

  &__body {
    margin-top: 12px;
  }

  &__more-link {
    @include link-primary;

    margin-top: 17px;

    .m-post-teaser--column & {
      flex-grow: 1;
      align-items: flex-end;
    }
  }

  &__image-link {
    position: relative;
    margin-right: 30px;

    @include respond(break(60)) {
      margin-right: 0;
      margin-bottom: 18px;
    }

    .m-post-teaser--column & {
      margin-top: 25px;
      margin-bottom: 25px;
    }

    &:hover,
    &:focus-visible {
      outline: 0;

      .m-post-teaser__picture::after {
        opacity: 0.48;
      }
    }
  }

  &__picture {
    @include lighten-image;
  }

  &__tags {
    margin-top: 27px;
  }
}
