// Application-Wide Mixins
// -----------------------------------

// Fluid Elements
// --------------------------------------------------------
// Set min and max sizes and breakpoints and let SCSS fluidly scale different properties in-between

// Adapted from https://www.sassmeister.com/gist/7f22e44ace49b5124eec
// Usage: @include fluid-type(font-size, 22px, 16px, break(130), break(50));
@mixin fluid-scale(
  $properties,
  $max-value,
  $min-value,
  $max-vw: break(130),
  $min-vw: break(60)
) {
  @each $property in $properties {
    #{$property}: $max-value;
  }

  /* stylelint-disable max-line-length */
  @media (max-width: $max-vw) {
    @each $property in $properties {
      // prettier-ignore
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }
  /* stylelint-enable max-line-length */

  @media (max-width: $min-vw) {
    @each $property in $properties {
      #{$property}: $min-value;
    }
  }
}

// Media queries
// --------------------------------------------------------
// Respond to viewport size
// $size -> viewport size
// $operator -> 'min' or 'max'
// $aspect -> 'width' or 'height'
@mixin respond($size, $operator: max, $aspect: width) {
  @media all and (#{$operator}-#{$aspect}: $size) {
    @content;
  }
}

// Respond to pixel density
// --------------------------------------------------------
@mixin respond-min-density($density: 1.5) {
  @media only screen and (min-resolution: $density) {
    @content;
  }
}
