.m-responsive-thumbnail {
  &__image {
    border-radius: 6px;

    @include respond(break(60)) {
      width: 100%;
      height: auto;
    }
  }
}
