.m-post-pagination {
  display: flex;
  justify-content: space-between;

  &__button {
    @include utility-primary;
    @include focus-default;

    width: calc(50% - 10px);
    padding: 10px 20px 9px;
    color: palette(primary);
    border: 1px solid palette(primary);
    border-radius: $border-radius;
    transition: all $duration $timing;
    transition-property: border-color, background-color, color;

    &:hover {
      color: palette(white);
      background-color: palette(secondary-dark);
      border-color: palette(secondary-dark);
    }

    &--next {
      margin-left: auto;
      text-align: right;
    }
  }

  &__button-text {
    display: inline-block;
    padding: 0 3px;

    @include respond(break(50)) {
      display: none;
    }
  }
}
