.m-footer {
  @include fluid-scale(padding-top, 70px, 65px);

  padding-bottom: 65px;

  &:not(.a-bg-white) {
    background-color:
      palette(
        secondary-pale
      ); // fallback if SetFooterBgColor fails
  }

  &__inner {
    @include container-primary;
  }

  &__main-content {
    display: flex;
    justify-content: space-between;

    // IE11 doesn't interpret this property correctly
    @supports (align-items: baseline) {
      align-items: baseline;
    }

    @include respond(break(60)) {
      flex-direction: column;
    }
  }

  &__start-block {
    order: 2;

    @include respond(break(60)) {
      order: 1;
    }
  }

  &__end-block {
    @include respond(break(60)) {
      order: 2;
      padding-top: 58px;
    }
  }

  &__logo {
    display: inline-block;
    width: 50px;
    height: 39.42px;
    margin-right: 17px;

    @include respond(break(60)) {
      display: block;
      width: 60px;
      height: 47.3px;
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  &__site-name {
    @include heading-quaternary;
    @include link-primary;

    display: block;

    @include respond(break(60)) {
      margin-bottom: 10px;
    }
  }

  &__contact,
  &__nav-list {
    @include list-unstyled;

    > li a:not(.m-footer__signup) {
      @include link-primary;
    }
  }

  &__nav-list {
    @include utility-primary;

    column-count: 2;

    > li {
      @include fluid-scale(padding-right, 82px, 40px);

      margin-bottom: 19px;
    }

    @supports (grid-template-columns: 1fr 1fr) {
      @include fluid-scale(grid-column-gap, 82px, 40px);

      display: grid;
      grid-template-rows: repeat(5, auto);
      grid-template-columns: repeat(2, 1fr);
      grid-auto-flow: column;
      grid-row-gap: 22px;

      > li {
        padding: 0;
        margin: 0;
      }
    }
  }

  &__contact {
    @include copy-primary;

    margin-left: 75px;

    @include respond(break(60)) {
      margin-left: 0;
    }

    > li:not(:first-child) {
      padding-top: 12px;
    }
  }

  &__signup {
    @include button-primary;

    display: inline-block;
    margin-top: 14px;

    @include respond(break(60)) {
      width: 100%;
    }
  }

  &__colophon {
    @include proto-copy;
    @include fluid-scale(padding-top, 77px, 47px);

    font-size: 16px;
    line-height: 1.666;
    color: palette(primary);
    letter-spacing: 0.62px;
  }

  .button-primary {
    @include button-primary;

    display: block;
    margin-top: 48px;
  }

  .form-primary {
    padding-top: 43px;
    filter: blur(10px);
    opacity: 0;
    transition:
      opacity $duration - 0.1 cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s,
      filter $duration - 0.1 cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s,
      transform $duration - 0.1 cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
    transform: scale(1.07, 1.07);
  }

  .hidden-form {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0s linear $duration;

    &.open {
      max-height: 9999px;
      transition: max-height 0s linear;

      .form-primary {
        filter: blur(0);
        opacity: 1;
        transform: scale(1, 1);
      }
    }
  }
}
