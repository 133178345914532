.m-partner-list {
  &__grid-container {
    @include fluid-scale(padding-top, 72px, 50px);
    @include fluid-scale(margin-left margin-right, -27px, -15px);

    display: flex;
    flex-wrap: wrap;

    @supports (grid-column-gap: 55px) {
      @include fluid-scale(grid-column-gap, 55px, padding(small));
      @include fluid-scale(grid-row-gap, padding(large), padding(medium));

      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-right: 0;
      margin-left: 0;
    }

    @include respond(break(60)) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    @include fluid-scale(padding-bottom, 37px, padding(small));
    @include fluid-scale(margin-left margin-right, 27px, 15px);
    @include fluid-scale(margin-bottom, padding(large), padding(medium));

    width: 42%;
    border-bottom: 1px solid palette(primary);

    @include respond(break(60)) {
      width: 100%;
    }

    @supports (grid-column-gap: 55px) {
      width: auto;
      margin-right: 0;
      margin-bottom: 0;
      margin-left: 0;
    }
  }

  &__title {
    @include heading-primary;

    text-transform: none;
  }

  &__subtitle {
    @include heading-secondary;
  }

  &__title + &__subtitle {
    @include fluid-scale(padding-top, 42px, padding(small));
  }

  &__desc {
    margin-top: 12px;
  }

  &__name {
    @include heading-primary;

    transition: color $duration $timing;
  }

  &__link {
    display: block;
    height: 100%;

    &:hover,
    &:focus-visible {
      .m-partner-list__name {
        color: palette(secondary-dark);
      }

      .m-partner-list__figure--image::after {
        opacity: 0.48;
      }
    }
  }

  &__logo {
    max-width: 100%;
    max-height: 100%;
  }

  &__figure {
    @include fluid-scale(margin-bottom, 40px, padding(small));

    display: flex;
    align-items: center;

    &--logo {
      height: 75px;
    }

    &--image {
      position: relative;

      @include lighten-image;
    }
  }

  &__body {
    padding-top: 6px;
  }
}
