.m-link-with-icon {
  @include link-primary;
  @include utility-primary;

  display: flex;

  &__icon {
    position: relative;
    top: -4px;
  }

  &__text + &__icon {
    margin-left: 8px;
  }

  &__icon + &__text {
    margin-bottom: 8px;
    margin-left: 13px;
  }
}
