// Global z-index stack
// -----------------------------------

.m-nav-utility__popover.is-open {
  z-index: 90;
}

.m-nav-utility__mobile-close {
  z-index: 100;
}

.m-header {
  z-index: 75;
}

.m-hero__button--open {
  z-index: 20;
}

.m-hero__info {
  z-index: 10;

  &.open {
    z-index: 25;
  }
}
