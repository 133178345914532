.m-tags {
  @include list-unstyled;
  @include proto-utility;

  display: inline-block;
  font-size: 13px;
  color: palette(neutral-30);
  letter-spacing: 2.32px;

  &:not(:last-child) {
    padding-bottom: 56px;
  }

  &__icon {
    padding-right: 8px;
  }

  &__item {
    display: inline-block;
    transition: color $duration $timing;

    &:hover {
      color: palette(secondary-dark);
    }
  }
}
