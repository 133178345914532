// Typography
// -----------------------------------

// Typography mixins and utility classes.

// These should be limited to `font` and other typographic properties only.

// Alignment
// --------------------------------------------------------
.t-align-center {
  text-align: center;
}

// Headings
// --------------------------------------------------------
@mixin proto-heading {
  font-family: $font-stack-heading;
  font-weight: $regular;
  color: palette(primary);
}

@mixin proto-utility {
  font-family: $font-stack-base;
  font-weight: $medium;
  text-transform: uppercase;
}

@mixin heading-primary {
  @include proto-heading;

  font-size: 28px;
  line-height: 1.179;
  letter-spacing: -0.5px;
}

.t-heading-primary {
  @include heading-primary;
}

@mixin heading-secondary {
  @include proto-utility;
  @include fluid-scale(letter-spacing, 2.86px, 2.5px);
  @include fluid-scale(line-height, 1.312, 1.625);

  font-size: 16px;
  color: palette(primary);
}

.t-heading-secondary {
  @include heading-secondary;
}

@mixin heading-tertiary {
  @include proto-heading;

  font-size: 24px;
  line-height: 1.167;
  letter-spacing: -0.43px;
}

.t-heading-tertiary {
  @include heading-tertiary;
}

@mixin heading-quaternary {
  @include proto-heading;

  font-size: 20px;
  line-height: 1.3;
  letter-spacing: -0.36px;
}

.t-heading-quaternary {
  @include heading-quaternary;
}

// Utility
// --------------------------------------------------------

@mixin utility-primary {
  @include proto-utility;

  font-size: 14px;
  line-height: 1.214;
  color: palette(primary);
  letter-spacing: 2.5px;
}

.t-utility-primary {
  @include utility-primary;
}

@mixin utility-secondary {
  @include proto-heading;

  font-size: 17px;
  line-height: 1;
  letter-spacing: -0.1px;
}

.t-utility-secondary {
  @include utility-secondary;
}

// Copy
// --------------------------------------------------------

@mixin proto-copy {
  font-family: $font-stack-base;
  font-weight: $light;
}

@mixin copy-primary {
  @include proto-copy;

  font-size: 17px;
  line-height: 1.471;
  letter-spacing: 0.7px;
}

.t-copy-primary {
  @include copy-primary;
}

@mixin copy-secondary {
  @include proto-copy;

  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.66px;
}

.t-copy-secondary {
  @include copy-secondary;
}

@mixin copy-large {
  @include proto-copy;
  @include fluid-scale(font-size, 20px, 18px);
  @include fluid-scale(letter-spacing, 0.8px, 0.72px);
  @include fluid-scale(line-height, 1.5, 1.389);
}

.t-copy-large {
  @include copy-large;
}

// Links
// --------------------------------------------------------

@mixin link-underlined {
  text-decoration: underline;
}

.t-link-underlined {
  @include link-underlined;
}


@mixin link-primary {
  color: palette(primary);
  transition: color $duration $timing;

  &:hover {
    color: palette(secondary-dark);
  }
}

.t-link-primary {
  @include link-primary;
}

// Lists
// --------------------------------------------------------
@mixin list-unstyled {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.t-list-unstyled {
  @include list-unstyled;
}

@mixin list-unordered-primary {
  padding: 0;
  margin: 0 0 0 1em;
}

.t-list-unordered-primary {
  @include list-unordered-primary;
}

@mixin list-ordered-primary {
  padding: 0;
  margin: 0 0 0 1em;
}

.t-list-ordered-primary {
  @include list-ordered-primary;
}
