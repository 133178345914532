.m-single-fellow {
  &__header {
    display: flex;
    padding-bottom: 35px;

    @include respond(break(60)) {
      flex-direction: column;
      padding-bottom: 15px;
    }
  }

  &__title,
  &__info-group {
    padding-bottom: 14px;
  }

  &__title {
    @include heading-primary;
    @include link-primary;

    @include respond(break(60)) {
      padding-top: 20px;
    }
  }

  &__image-container {
    margin-right: padding(small);

    @include respond(break(60)) {
      margin-right: 0;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    object-fit: cover;
  }

  &__label {
    @include utility-primary;

    padding-bottom: 5px;
  }

  &__read-more {
    @include utility-primary;

    display: block;
    padding-top: 50px;
    padding-bottom: 20px;
  }

  &__quote-block {
    @include fluid-scale(padding-top, 45px, padding(small));

    margin: 0;
  }

  &__quote {
    @include heading-primary;
  }

  &__attribution {
    padding-top: 12px;
    font-style: italic;
  }

  &__icon-container {
    position: relative;
  }

  &__icon {
    position: absolute;
    margin-left: 10px;
    transition: opacity $duration $timing;
  }

  &__expand-bio {
    @include focus-default;

    display: flex;
    padding-top: 30px;
    font-size: 14px;
    color: palette(primary);
    letter-spacing: 2.5px;
    list-style: none;
    cursor: pointer;
    transition: color $duration $timing;

    &::before {
      display: none;
    }

    &::-webkit-details-marker {
      display: none;
    }

    &:hover {
      color: palette(secondary-dark);
    }

    &--read-more {
      align-items: center;
      line-height: 1.6rem;
    }
  }

  &__accordion[open] {
    .m-single-fellow__expand-bio {
      padding-bottom: 22px;
    }

    .m-single-fellow__icon--plus {
      opacity: 0;
    }
  }

  &__accordion {
    padding: 0;
  }
}
