@use 'sass:color';

// State
// --------------------

// Mixins and classes for setting styles based on component state.
// Classes are prepended with `is-` or `has-`.

@mixin has-scroll-lock {
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -25;
    width: 100%;
    height: 100%;
    content: '';
    transition: background-color 0.2s linear;
  }

  &.has-scroll-lock {
    position: absolute;
    width: 100%;
    overflow: hidden;

    &::after {
      z-index: 0;
      background-color: color.adjust(palette(white), $alpha: -0.9);
    }
  }
}

// apply `has-scroll-lock` mixin to body for overlays
body {
  @include has-scroll-lock;
}
